<template>
  <b-modal
    v-model="modal"
    :title="$t('label.appUpdateDetails')"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    body-class="max-h-325px"
  >
    <!-- begin:: Modal Body -->
    <b-form-group class="mb-0">
      <template v-for="(i, index) in formFields">
        <div :key="index" class="px-2 mb-6">
          <!-- begin:: Platform Label -->
          <div
            v-if="i.model == 'platform'"
            class="mb-6 font-size-lg font-weight-bolder"
          >
            <span>{{ $t(`label.${i.title}`) }}:</span>
            <span class="pl-2">{{ appUpdateObj[i.model] }}</span>
          </div>
          <!-- end:: Platform Label -->

          <!-- begin:: Form input -->
          <AppFormField
            v-else
            v-model="$v.appUpdateObj[i.model].$model"
            :state="validateObjState('appUpdateObj', i.model)"
            :validationValue="$v.appUpdateObj[i.model]"
            :title="$t(`label.${i.title}`)"
            :solid="true"
            :validators="i.validators"
            :formType="i.formType"
            :options="i.options"
            :disabled="isFormDisabled"
          ></AppFormField>
          <!-- end:: Form Input -->
        </div>
      </template>
    </b-form-group>
    <!-- end:: Modal Body -->

    <!-- begin:: Modal Footer -->
    <template #modal-footer>
      <template v-if="isShowActions">
        <b-button class="mr-2" variant="white" @click="actionDiscard">{{
          $t('label.discard')
        }}</b-button>

        <b-button variant="primary" @click="actionSaveChanges">{{
          $t('label.saveChanges')
        }}</b-button>
      </template>
      <b-button v-else variant="primary" @click="actionDiscard">{{
        $t('label.close')
      }}</b-button>
    </template>
    <!-- end:: Modal Footer -->
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import commonMixin from '@/core/mixins/common.mixin';
  import { listService } from '@/core/services';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required, url, numeric } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    APP_INFO_GET_APP_INFO,
    APP_INFO_UPDATE_APP_INFO,
    APP_INFO_INITIAL_APP_INFO_STATE,
    APP_INFO_INITIAL_UPDATE_APP_INFO_STATE
  } from '@/core/store/app-info.module';

  export default {
    name: 'AppUpdateDetailsModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      }
    },
    data: () => ({
      modal: false,
      appUpdateObj: {
        platform: null,
        version: null,
        forceUpdate: 0,
        url: null,
        message: null
      },
      formFields: []
    }),
    computed: {
      appInfoComplete() {
        return this.$store.state.appInfo.appInfo.complete;
      },
      updateAppInfoComplete() {
        return this.$store.state.appInfo.updateAppInfo.complete;
      },
      isFormDisabled() {
        return !this.checkAccessRight(MODULE.APP_INFO, [
          ACTION.APP_INFO_UPDATE
        ]);
      },
      isShowActions() {
        return this.checkAccessRight(MODULE.APP_INFO, [ACTION.APP_INFO_UPDATE]);
      }
    },
    watch: {
      value() {
        if (this.value) {
          this.getAppInfo(this.id);
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      appInfoComplete() {
        let response = this.$store.state.appInfo.appInfo;
        let title = i18nHelper.getMessage('label.getAppInfo');
        let initialStateAction = APP_INFO_INITIAL_APP_INFO_STATE;
        let successAction = (response) => {
          this.getAppInfoCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateAppInfoComplete() {
        let response = this.$store.state.appInfo.updateAppInfo;
        let title = i18nHelper.getMessage('label.updateAppInfo');
        let initialStateAction = APP_INFO_INITIAL_UPDATE_APP_INFO_STATE;
        let successAction = () => {
          this.actionCloseModal();
          this.initialList();
        };
        let errorAction = () => {
          this.actionCloseModal();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    mounted() {
      this.initFormFields();
    },
    methods: {
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getAppInfoCompleteActions(response) {
        this.appUpdateObj = response.data;
        this.modal = true;
        this.$v.$reset();
      },
      actionDiscard() {
        this.actionCloseModal();
      },
      actionSaveChanges() {
        if (this.validationCheck('appUpdateObj')) {
          this.updateAppInfo(this.id, this.appUpdateObj);
        }
      },
      resetForm() {
        this.appUpdateObj = {
          platform: null,
          version: null,
          forceUpdate: 0,
          url: null,
          message: null
        };

        this.$v.$reset();
      },
      actionCloseModal() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      getAppInfo(id) {
        this.$store.dispatch(APP_INFO_GET_APP_INFO, {
          id
        });
      },
      updateAppInfo(id, data) {
        this.$store.dispatch(APP_INFO_UPDATE_APP_INFO, {
          id,
          data
        });
      },
      initFormFields() {
        this.formFields.push(
          {
            model: 'platform',
            title: 'platform',
            disabled: true
          },
          {
            model: 'version',
            title: 'version',
            validators: ['required', 'numeric']
          },
          {
            model: 'forceUpdate',
            title: 'forceUpdate',
            validators: ['required'],
            formType: 'select',
            options: listService.getYesNoList(false)
          },
          {
            model: 'url',
            title: 'url',
            validators: ['required', 'url']
          },
          {
            model: 'message',
            title: 'message',
            validators: ['required']
          }
        );
      }
    },
    validations: {
      appUpdateObj: {
        version: {
          required,
          numeric
        },
        forceUpdate: {
          required
        },
        url: {
          required,
          url
        },
        message: {
          required
        }
      }
    }
  };
</script>

<style lang="scss"></style>
